export default [
  {
    path: '/cpat',
    name: 'cpat',
    component: () => import('@/views/2_project/cpat_list/CpatList.vue'),
    meta: {
      // pageTitle: 'Cpat',
      // breadcrumb: [
      //   {
      //     text: 'Cpat',
      //     active: true,
      //   },
      // ],
      resource: 'cpat',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/cpat-list',
    name: 'cpat-list',
    component: () => import('@/views/2_project/cpat_list/CpatListSingle.vue'),
    meta: {
      // pageTitle: 'Import Results',
      // breadcrumb: [
      //   {
      //     text: 'Import Results',
      //     active: true,
      //   },
      // ],
      resource: 'cpat-list',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/customer-list',
    name: 'customer-list',
    component: () => import('@/views/2_project/cpat_list/CpatListSingle.vue'),
    meta: {
      // pageTitle: 'Import Results',
      // breadcrumb: [
      //   {
      //     text: 'Import Results',
      //     active: true,
      //   },
      // ],
      resource: 'customer-list',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/event-list',
    name: 'event-list',
    component: () => import('@/views/2_project/cpat_list/CpatListSingle.vue'),
    meta: {
      // pageTitle: 'Import Results',
      // breadcrumb: [
      //   {
      //     text: 'Import Results',
      //     active: true,
      //   },
      // ],
      resource: 'event-list',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/contract-list',
    name: 'contract-list',
    component: () => import('@/views/2_project/cpat_list/CpatListSingle.vue'),
    meta: {
      // pageTitle: 'Import Results',
      // breadcrumb: [
      //   {
      //     text: 'Import Results',
      //     active: true,
      //   },
      // ],
      resource: 'contract-list',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/cpat-add',
    name: 'cpat-add',
    component: () => import('@/views/2_project/cpat_add/CpatAdd.vue'),
    meta: {
      // pageTitle: 'Cpat Add',
      // breadcrumb: [
      //   {
      //     text: 'Cpat Add',
      //     active: true,
      //   },
      // ],
      resource: 'cpat-add',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/cpat-edit/:id',
    name: 'cpat-edit',
    component: () => import('@/views/2_project/cpat_add/CpatEdit.vue'),
    meta: {
      // pageTitle: 'Cpat Edit',
      // breadcrumb: [
      //   {
      //     text: 'Cpat Edit',
      //     active: true,
      //   },
      // ],
      resource: 'cpat-edit',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },
];
