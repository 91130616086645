export default [
    {
      path: '/resources/',
      name: 'resources',
      component: () => import('@/views/resources/Resources.vue'),
      meta: {
        // pageTitle: 'User List',
        // breadcrumb: [
        //   {
        //     text: 'Users',
        //   },
        //   {
        //     text: 'User List',
        //     active: true,
        //   },
        // ],
        Permission: 'no_permission_required',
      },
    },
 
   
  ];
  