export default [
  {
    path: '/user/user-list',
    name: 'user-list',
    component: () => import('@/views/user/user-list/UserList.vue'),
    meta: {
      // pageTitle: 'User List',
      // breadcrumb: [
      //   {
      //     text: 'Users',
      //   },
      //   {
      //     text: 'User List',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/user/user-edit/:id',
    name: 'user-edit',
    component: () => import('@/views/user/user-add/UserEdit.vue'),
    meta: {
      // pageTitle: 'User Edit',
      // breadcrumb: [
      //   {
      //     text: 'Users',
      //   },
      //   {
      //     text: 'User Edit',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/user/user-add',
    name: 'user-add',
    component: () => import('@/views/user/user-add/UserAdd.vue'),
    meta: {
      // pageTitle: 'User Add',
      // breadcrumb: [
      //   {
      //     text: 'Users',
      //   },
      //   {
      //     text: 'User Add',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/user/user-profile',
    name: 'user-profile',
    component: () => import('@/views/user/user-profile/AccountSetting.vue'),
    meta: {
      // pageTitle: 'User Profile',
      // breadcrumb: [
      //   {
      //     text: 'User',
      //   },
      //   {
      //     text: 'User Profile',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
];
