export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      // pageTitle: 'Settings',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     active: true,
      //   },
      // ],
      resource: 'settings',
      action: 'read',
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/userrole',
    name: 'settings-userrole',
    component: () => import('@/views/settings/Permissions/role-list/RoleList.vue'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/general',
    name: 'settings-general',
    component: () => import('@/views/settings/General/General.vue'),

    meta: {
      // pageTitle: 'General Settings',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'General Settings',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/questions',
    name: 'settings-questions',
    component: () => import('@/views/settings/Questions/Questions'),

    meta: {
      // pageTitle: 'Questions',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Questions',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/addSurvey',
    name: 'settings-addSurvey',
    component: () => import('@/views/settings/Surveys/AddNewSurvey'),

    meta: {
      // pageTitle: 'Add New Survey',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Add New Survey',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/groups',
    name: 'settings-groups',
    component: () => import('@/views/settings/Groups/Groups'),

    meta: {
      // pageTitle: 'Groups',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Groups',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/growthProfile',
    name: 'settings-growthProfile',
    component: () => import('@/views/settings/School-Growth/GrowthProfile'),

    meta: {
      // pageTitle: 'School Growth Profile',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Groups',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/dashboard',
    name: 'settings-dashboard',
    component: () => import('@/views/settings/Dashboard/Index'),

    meta: {
      // pageTitle: 'Index',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Dashboards',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/workshops',
    name: 'settings-workshops',
    component: () => import('@/views/settings/Dashboard/WorkShop/WorkShopList'),

    meta: {
      // pageTitle: 'Workshops',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Workshop',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/createWorkshop',
    name: 'settings-createWorkshop',
    component: () => import('@/views/settings/Dashboard/WorkShop/CreateWorkShop'),

    meta: {
      // pageTitle: 'Create Workshop',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Create Work Shop',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/workshopEdit/:id',
    name: 'workshopEdit',
    component: () => import('@/views/settings/Dashboard/WorkShop/CreateWorkShop'),

    meta: {
      // pageTitle: 'Workshop Edit',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Work Shop Edit',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/quote-preview',
    name: 'settings-quotePreview',
    component: () => import('@/views/settings/Dashboard/Quote/QuotePreview'),
    meta: {
      // pageTitle: 'Quote Preview',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Quote Preview',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/quotes',
    name: 'settings-quotes',
    component: () => import('@/views/settings/Dashboard/Quote/QuoteList'),

    meta: {
      // pageTitle: 'Quotes',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Quotes',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/createQuote',
    name: 'settings-createQuote',
    component: () => import('@/views/settings/Dashboard/Quote/CreateQuote'),

    meta: {
      // pageTitle: 'Create Quote',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Create Quote',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/contracts',
    name: 'settings-contracts',
    component: () => import('@/views/settings/Dashboard/Contract/ContractList'),

    meta: {
      // pageTitle: 'Contracts',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Contracts',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/leads',
    name: 'settings-leads',
    component: () => import('@/views/settings/Dashboard/Lead/LeadList'),

    meta: {
      // pageTitle: 'Leads',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Leads',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/timeAndEfford',
    name: 'settings-timeAndEfford',
    component: () => import('@/views/settings/Dashboard/Time&Effort/TimeEffort'),

    meta: {
      // pageTitle: 'Time & Effort',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     to: '/settings',
      //   },
      //   {
      //     text: 'Time & Effort',
      //     active: true,
      //   },
      // ],
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/grade-levels',
    name: 'settings-grade-levels',
    component: () => import('@/views/settings/General/GradeLevel/GradeLevels'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/summary',
    name: 'settings-summary',
    component: () => import('@/views/settings/General/Summary/SummaryList'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/summary/:id',
    name: 'survey-settings-summary',
    component: () => import('@/views/settings/General/Summary/SummaryList'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/surveys',
    name: 'settings-surveys',
    component: () => import('@/views/settings/Surveys/Survey-Settings/ListSurveys'),

    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/createSurvey',
    name: 'settings-createSurvey',
    component: () => import('@/views/settings/Surveys/Survey-Settings/CreateSurvey'),

    meta: {
      Permission: 'no_permission_required',
    },
  },
  {
    path: '/settings/surveyDetail/:id',
    name: 'settings-surveyDetail',
    component: () => import('@/views/settings/Surveys/Survey-Settings/SurveyDetail'),

    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/growthStatistic',
    name: 'settings-growthStatistic',
    component: () => import('@/views/settings/School-Growth/GrowthStatistic'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/settings/resources',
    name: 'settings-resources',
    component: () => import('@/views/settings/SetResources/ResourceUpload.vue'),

    meta: {
      Permission: 'settings_page_menu',
    },
  },

  {
    path: '/settings/resources/:id',
    name: 'resources-edit',
    component: () => import('@/views/settings/SetResources/ResourceEdit.vue'),

    meta: {
      Permission: 'resource_edit',
    },
  },

  {
    path: '/settings/support',
    name: 'settings-support',
    component: () => import('@/views/settings/SetTools/ToolUpload.vue'),

    meta: {
      Permission: 'settings_page_menu',
    },
  },

  {
    path: '/settings/support/:id',
    name: 'support-edit',
    component: () => import('@/views/settings/SetTools/ToolEdit.vue'),

    meta: {
      Permission: 'support_edit',
    },
  },
];
